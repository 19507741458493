
  import {
    computed,
    defineComponent,
    ref,
    onMounted,
    watch,
    getCurrentInstance,
  } from "vue";

  interface IPagination {
    page: number;
    total: number;
    rowsPerPage: number;
  }

  interface IHeaderConfiguration {
    name?: string;
    key: string;
    sortingField?: string;
    sortable?: boolean;
  }

  export default defineComponent({
    name: "TableData",
    emit: ["current-change", "sort", "items-per-page-change"],
    props: {
      tableHeader: {
        type: Array as () => Array<IHeaderConfiguration>,
        required: true,
      },

      tableData: { type: Array, required: true },
      emptyTableText: { type: String, default: "Not found data" },
      loading: { type: Boolean, default: false },
      currentPage: { type: Number, default: 1 },
      enableItemsPerPageDropdown: { type: Boolean, default: true },
      total: { type: Number, default: 0 },
      rowsPerPage: { type: Number, default: 10 },
      order: { type: String, default: "asc" },
      sortLabel: { type: String, default: "" },
    },

    components: {},
    
    setup(props, { emit }) {
      const data = ref(props.tableData);
      const currentSort = ref<string>("");
      const order = ref(props.order);
      const label = ref(props.sortLabel);
      const pagination = ref<IPagination>({
        page: 1,
        total: props.total,
        rowsPerPage: props.rowsPerPage,
      });

      const vnodeProps = getCurrentInstance()?.vnode.props || {};

      watch(data.value, () => {
        if ("onCurrentChange" in vnodeProps) {
          currentSort.value = label.value + order.value;
        } else {
          pagination.value.total = data.value.length;
        }
      });

      watch(
        () => props.rowsPerPage,

        (current, old) => {
          if (current !== old) {
            pagination.value.rowsPerPage = current;
          }
        }
      );

      onMounted(() => {
        currentSort.value = label.value + order.value;
        pagination.value.total = props.total ? props.total : data.value.length;
        pagination.value.rowsPerPage = props.rowsPerPage;

        // console.log(props.tableData);
      });

      const getItems = computed(() => {
        if ("onCurrentChange" in vnodeProps) {
          return data.value;
        } else {
          const clone = JSON.parse(JSON.stringify(data.value));
          const startFrom =
            pagination.value.page * pagination.value.rowsPerPage -
            pagination.value.rowsPerPage;
          return clone.splice(startFrom, pagination.value.rowsPerPage);
        }
      });

      const currentPageChange = (val) => {
        if ("onCurrentChange" in vnodeProps) {
          emit("current-change", val);
        } else {
          pagination.value.page = val;
        }
      };

      const setItemsPerPage = (event) => {
        if ("onItemsPerPageChange" in vnodeProps) {
          emit("items-per-page-change", parseInt(event.target.value));
        } else {
          pagination.value.rowsPerPage = parseInt(event.target.value);
        }
      };

      return {
        getItems,
        pagination,
        currentSort,
        setItemsPerPage,
        currentPageChange,
      };
    },
  });
