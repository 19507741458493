
/* eslint-disable no-undef */
import {
  computed,
  defineComponent,
  onMounted,
  ref,
  onBeforeMount,
  watch,
} from "vue";
import { useRoute, useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import axios from "axios";

import ApiService from "@/core/services/ApiService";
import DataTable from "@/components/common/TableData.vue";
import store from "@/store";
import _ from "lodash";
import { showModal, hideModal } from "@/core/helpers/dom";
import SolanaServices from "@/core/services/SolanaServices";
import { Field, ErrorMessage } from "vee-validate";

export default {
  name: "AppraisalsList",
  components: {
    DataTable,
    Field,
  },
  setup(props) {
    const route = useRoute();
    const router = useRouter();
    const tipoRol = ref<string>("");


    const userRole = ref<any>();
    const tipoCita = ref<number>(0);
    const loading = ref<boolean>(false);
    const user = ref<any>({ user:"", type:"" });

    const id = ref<any>(null);
    const listStatus = ref<any>([]);
    const modalStatusRef = ref<any>();

    let status_ref = ref<any>("");
    let type_offer = ref<any>("");
    let drop_down_status = ref<boolean>(false);
    let edit_final_offer = ref<boolean>(false);
    let edit_initial_offer = ref<boolean>(false);

    const list_agencies = ref([]);

    const Row = ref<any>({
      appraise: {
        id: "0",
        date: "13/01/2022",
        appraiser: "Valuador uno",
        last_offer: "$45000.00",
        last_comment:"Abalaustrado-Cencerrear-Basurero Engarrafador Cenco Cencerrear Deschavetado",
        file: "http://ec2-54-208-154-159.compute-1.amazonaws.com/api/leads/storage/tN8hYKOywVTIHGXTeMNow7SUyIpTnVjmTPKcS4hE.jpg",
        status: "",
        status_id: "",
        type: "3",
        agency: "",
        agency_id: "0",
      },
      appraiser: {
        id: "0",
        name: "Joseph W. Lopez",
        email: "joseph.lopez@solana.com",
        phone: "3314492378",
      },
      customer: {
        id: "0",
        name: "Kristin E. Murphy",
        email: "anasofía32@gmail.com",
        phone: "3365781953",
      },
      vehicle: {
        brand: "Hyundai",
        model: "Tucson",
        year: "2020",
        version: "CZD 1/W",
        mileage: "50,000",
        owner: "2",
        color: "",
      },
      negotiation: {
        final_offer: "158,000",
        initial_offer: "150,000",
        intelli_offer: "158,000",
        temp_offer: "",
        appraiser: "Valuador Uno",
        last_comment: "El ultimo comentario",
        last_offer: "$45000.00",
        new_comment: "",
        date: "13/01/2022",
        comments: [
          {
            id: "1",
            offer: "158,000",
            date: "13/01/2022",
            user: "Valuador uno",
            comment: "El comentario",
          },
        ],
      },
      result: {
        status: "",
        type: "Compra directa",
        agency_id: "0",
        agency: "",
        user: "",
        date: "",
        comment: "",
      },
      interested_vehicle: {
        id: "0",
        vehicle: "Hyundai Tucson 2020 CZD 1/W",
      },
      documents: {
        owner: {
          title: "",
          sort: 1,
          data: [{}],
        },
        contracts: {
          title: "",
          sort: 2,
          data: [{}],
        },
        customer: {
          title: "",
          sort: 3,
          data: [{}],
        },
        vehicle: {
          title: "",
          sort: 4,
          data: [{}],
        },
        role: {
          title: "",
          sort: 5,
          data: [{}],
        },
        agency: {
          title: "",
          sort: 6,
          data: [{}],
        },
      },
    });

    const getUser = async () => {
      try {
        const { data } = await ApiService.get("/api/auth/user");
        // console.log(data);
        
        user.value = data.data[0];
      } catch (error) {
        console.log(error);
      }
    };

    const getStatus = async () => {
      try {
        const { data } = await ApiService.get("/api/leads/avaluos-status");
        listStatus.value = data.data;

        // console.log(data);
      } catch (error) {
        console.log(error);
      }
    };

    const getData = async () => {
      loading.value = true;

      try {
        const { data } = await ApiService.get("/api/leads/avaluos/" + id.value);
        if (data.success) {
          (data.data.client != undefined)? (Row.value.customer = data.data.client) : false;
          (data.data.valuador != undefined)? (Row.value.appraiser = data.data.valuador) : false;
          (data.data.interested_vehicle != undefined)? (Row.value.interested_vehicle = data.data.interested_vehicle) : false;

          if (data.data.avaluo_info != undefined) {
            tipoCita.value = data.data.avaluo_info.tipo_cita;
            
            Row.value.appraise = data.data.avaluo_info;
            Row.value.appraise.last_comment = data.data.avaluo_info.comentario;
            Row.value.appraise.file = data.data.avaluo_info.diagnostico;
            Row.value.appraise.appraiser = data.data.avaluo_info.valuador;
            Row.value.appraise.date = data.data.avaluo_info.fecha;
            Row.value.appraise.user = data.data.avaluo_info.user;
            Row.value.appraise.type = data.data.avaluo_info.tipo_cita;

            Row.value.result.agency_id = data.data.avaluo_info.agency_id;

            // console.log(Row.value.appraise);
          }

          if (data.data.vehicle != undefined) {
            Row.value.vehicle = data.data.vehicle;
            Row.value.vehicle.mileage = data.data.vehicle.Mileage;
            Row.value.vehicle.owner = data.data.vehicle.Owners;
          }

          if (data.data.status != undefined) {
            Row.value.appraise.status = data.data.status.status;
            Row.value.appraise.status_id = data.data.status.id;
          }

          if (data.data.negotiation != undefined) {
            Row.value.negotiation = data.data.negotiation;
            Row.value.negotiation.initial_offer = formatPrice(
              Row.value.negotiation.initial_offer
            );
            Row.value.negotiation.intelli_offer = formatPrice(
              Row.value.negotiation.intelli_offer
            );
            Row.value.negotiation.final_offer = formatPrice(
              Row.value.negotiation.final_offer
            );
            // (Row.value.negotiation.initial_offer <= 0)? edit_initial_offer.value = true : false;

            if (data.data.negotiation.comments.length > 0) {
              for (let key in data.data.negotiation.comments) {
                Row.value.negotiation.last_offer = formatPrice(data.data.negotiation.comments[key].offer);
                Row.value.negotiation.last_comment = data.data.negotiation.comments[key].comment;
                Row.value.negotiation.appraiser = data.data.negotiation.comments[key].user;
                Row.value.negotiation.date = data.data.negotiation.comments[key].date;

                // console.log(Row.value.negotiation.comments);

                break;
              }
            }
          }

          if (data.data.documents != undefined && data.data.documents.length > 0) {
            for (let row of data.data.documents) {
              // console.log(row);

              let type = "null";

              switch (row.type) {
                case "Vehículo":
                  type = "vehicle";
                  break;
                case "De propiedad":
                  type = "owner";
                  break;
                case "Cambio de rol":
                  type = "role";
                  break;
                case "Contratos":
                  type = "contracts";
                  break;
                case "Agencia fiscal":
                  type = "agency";
                  break;
                case "Identificación del cliente":
                  type = "customer";
                  break;
              }

              if (Row.value.documents[type] != undefined) {
                (Row.value.documents[type].title == "")? (Row.value.documents[type].title = row.type) : false;
                Row.value.documents[type].data.push(row);
              }

              // if(Row.value.documents[row.type] == undefined){
              //   Row.value.documents[row.type] = [];
              // }

              // if(Row.value.documents[row.type] != undefined){
              //   Row.value.documents[row.type].push(row);
              // }
            }
          }

          (data.data.result != undefined)? Row.value.result = data.data.result : false;

          // console.log(Row.value);
        }
      } catch (error) {
        // console.log(error);
        Swal.fire({
          icon: "error",
          title: "Ups!!! error en el servidor",
          text: error,
        });
      }

      loading.value = false;
    };

    const getAgencies = async () => {
      try {
        const { data } = await ApiService.get("/api/site/agencies?showing=100");
        list_agencies.value = data.data.agency.data;
        // console.log(data.data.agency.data);
        // console.log(list_agencies.value);
      } catch (error) {
        console.log(error);
      }
    };

    const setAgencyData = async () => {
      for (let key in list_agencies.value){
        if(list_agencies.value[key]["id"] == Row.value.appraise.agency_id){
          let agency = `${list_agencies.value[key]["name"]}`;
          Row.value.appraise.agency = agency;
          Row.value.result.agency = agency;
          Row.value.result.agency_id = Row.value.appraise.agency_id;
        }
      }
    };
    
    const editOffer = async (type) => {
      if (Row.value.appraise.status == "Negociación") {
        type_offer.value = type;

        if (type_offer.value == "initial") {
          edit_initial_offer.value = true;
          Row.value.negotiation.temp_offer = Row.value.negotiation.initial_offer;
          Row.value.negotiation.initial_offer = "";
        } else if (type_offer.value == "final") {
          edit_final_offer.value = true;
          Row.value.negotiation.temp_offer = Row.value.negotiation.final_offer;
          Row.value.negotiation.final_offer = "";
        }
      } else {
        Swal.fire({
          icon: "warning",
          title: "Error de edición",
          text: "En el estatus actual no es posible editar la oferta",
        });
      }
    };

    const cancelOffer = async () => {
      if (type_offer.value == "initial") {
        edit_initial_offer.value = false;
        Row.value.negotiation.initial_offer = Row.value.negotiation.temp_offer;
        Row.value.negotiation.temp_offer = "";
        Row.value.negotiation.new_comment = "";
      } else if (type_offer.value == "final") {
        edit_final_offer.value = false;
        Row.value.negotiation.final_offer = Row.value.negotiation.temp_offer;
        Row.value.negotiation.temp_offer = "";
        Row.value.negotiation.new_comment = "";
      }
    };

    const addOffer = async () => {
      let offer = "";
      let nueric_offer = 0;

      if (type_offer.value == "initial" && Row.value.negotiation.initial_offer != "") {
        offer = Row.value.negotiation.initial_offer;
        nueric_offer = Row.value.negotiation.initial_offer;
      } else if (type_offer.value == "final" && Row.value.negotiation.final_offer != "") {
        offer = Row.value.negotiation.final_offer;
        nueric_offer = Row.value.negotiation.final_offer;
      }
      
      if (offer == "" || parseInt(offer) < 1 || isNaN(nueric_offer)) {
        Swal.fire({
          icon: "warning",
          title: "Ingresa una oferta válida",
          text: "Ingresaste como oferta: " + offer,
        });

        return false;
      }

      if ( Row.value.negotiation.new_comment == "" || !Row.value.negotiation.new_comment ) {
        Swal.fire({
          icon: "warning",
          title: "Ingresa un comentario",
        });

        return false;
      }

      let formData = new FormData();
      formData.append("offer", offer);
      formData.append("comment", Row.value.negotiation.new_comment);

      if (Row.value.negotiation.new_comment && offer) {
        try {
          const { data } = await ApiService.post(`/api/leads/avaluos/registar-oferta/${id.value}`, formData);
          // console.log(data);

          if (data.success) {
            Row.value.negotiation.comments.unshift({
              id: "0",
              date: data.data.date,
              user: data.data.user,
              offer: data.data.offer,
              comment: data.data.comment,
            });

            // console.log(Row.value.negotiation.comments);

            Row.value.negotiation.last_comment = Row.value.negotiation.new_comment;
            Row.value.negotiation.last_offer = data.data.offer;
            // Row.value.negotiation.appraiser = data.data.user;
            Row.value.negotiation.date = data.data.date;
            Row.value.negotiation.appraiser = user.value?.user;
            
            Row.value.negotiation.temp_offer = "";
            Row.value.negotiation.new_comment = "";
            (type_offer.value == "initial")? edit_initial_offer.value = false : edit_final_offer.value = false ;
          } else {
            Swal.fire({
              icon: "error",
              text: data.message,
            });
          }
        } catch (error) {
          // console.log("Tiene error");
          // console.log(error.request.response.errors.string());
          // let message = error.request.response.errors.string();

          Swal.fire({
              icon: "error",
              title: "Error al intentar guardar"
              // text: message,
            });
        }
      }
    };

    const changeCheckedDocument = async (checked, data_row) => {
      try {
        let type = "";
        let formData = new FormData();
        formData.append("id_doc", data_row.id_doc);
        checked == false
          ? formData.append(
              "id_valuation_document",
              data_row.id_valuation_document
            )
          : false;

        const { data } = await ApiService.post(
          "/api/leads/avaluos/documentos/" + id.value,
          formData
        );

        // console.log(data);

        switch (data_row.type) {
          case "Vehículo":
            type = "vehicle";
            break;
          case "De propiedad":
            type = "owner";
            break;
          case "Cambio de rol":
            type = "role";
            break;
          case "Contratos":
            type = "contracts";
            break;
          case "Agencia fiscal":
            type = "agency";
            break;
          case "Identificación del cliente":
            type = "customer";
            break;
        }

        if (data.success == true) {
          if (Row.value.documents[type].data != undefined) {
            for (let key in Row.value.documents[type].data) {
              if (
                Row.value.documents[type].data[key].id_doc != undefined &&
                Row.value.documents[type].data[key].id_doc == data_row.id_doc
              ) {
                // console.log("Entro y asigno el id", data.data[0]);
                Row.value.documents[type].data[key].id_valuation_document =
                  data.data[0] != undefined ? data.data[0] : null;
                break;
              }
            }
          }
        } else {
          Swal.fire({
            icon: "warning",
            title: "No se pudo guardar el registro",
            text: data.message,
          });

          return false;
        }
      } catch (error) {
        console.log(error);
      }
    };

    const formatPrice = (value) => {
      // console.log(value);
      let val = (value / 1).toFixed(2);
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    const changeDropDownStatus = () => {
      drop_down_status.value = !drop_down_status.value;
    };

    const changeStatus = (status) => {
      drop_down_status.value = false;
      status_ref.value = status;
      // console.log("status", status);

      if (status_ref.value == "Documentación") {
        if (Row.value.negotiation.initial_offer != "" && Row.value.negotiation.final_offer != "") {
          saveStatus();
        } else {
          Swal.fire({
            icon: "error",
            position: "center",
            showConfirmButton: true,
            title:"Se necesita tener oferta inicial y oferta final para continuar el cambio de estatus",
          });
        }
      } else {
        showModal(modalStatusRef.value);
      }
    };

    const getTipoRol = async () => {
        const {data} = await SolanaServices.getTipoRol();
        tipoRol.value = data.data[0].type;
    }

    const saveStatus = async () => {
      let save_data = {};
      let validate = true;

      save_data = {
        type: Row.value.result.type,
        comments: Row.value.result.comment,
        status: status_ref.value == "Cancelado" ? 4 : 3,
        agency: status_ref.value == "Cancelado" ? 0 : Row.value.appraise.agency_id,
      };
      
      if (status_ref.value == "Documentación") {
        save_data = { status: 2 };
      } else if (status_ref.value == "Comprado") {
        for (let index in Row.value.documents) {
          for (let key in Row.value.documents[index].data) {
            if ( Row.value.documents[index].data[key].required == "Si" || Row.value.documents[index].data[key].required == "1" || Row.value.documents[index].data[key].required == "true" || Row.value.documents[index].data[key].required == true ) {
              (Row.value.documents[index].data[key].id_valuation_document == undefined || Row.value.documents[index].data[key].id_valuation_document <= 0)? validate = false : false ;
            }
          }
        }
      }
      
      if (validate) {
        await axios.post(process.env.VUE_APP_API_URL + `/api/leads/avaluos/status/${id.value}`, save_data ).then((response) => {
            // console.log("response", response);

            if (response.data.success) {
              if (status_ref.value != "Documentación") {
                hideModal(modalStatusRef.value);
              }

              let date = new Date;
              let day = date.getDay();
              let month = date.getMonth() + 1;
              let year = date.getFullYear();
              
              Row.value.appraise.status = status_ref.value;
              Row.value.result.agency = Row.value.appraise.agency;
              Row.value.result.user = user.value?.user;

              Row.value.result.date = `${year}-`;

              if(month < 10){
                Row.value.result.date = Row.value.result.date + `0${month}-`;
              } else {
                Row.value.result.date = Row.value.result.date + `${month}-`;
              }
              
              if(day < 10){
                Row.value.result.date = Row.value.result.date + `0${day}`;
              } else {
                Row.value.result.date = Row.value.result.date + `${day}`;
              }

            } else {
              Swal.fire({
                icon: "error",
                position: "center",
                showConfirmButton: true,
                title: "Hubo un error en el servidor",
              });
            }
          })
          .catch((error) => {
            // console.log("error ", error.response.data);
            
            if (error.response) {
              let message = (typeof error.response.data.errors === "object") ? Object.values(error.response.data.errors).toString().replace(",", "</br>") : error.response.data.message;

              Swal.fire({
                icon: "error",
                position: "center",
                showConfirmButton: true,
                title:message,
              });
            } else {
              console.log("error", error);
              Swal.fire({
                icon: "error",
                title: "Ups!!! error en el servidor",
                text: error,
              });
            }
          });
      } else {
        Swal.fire({
          icon: "error",
          position: "center",
          showConfirmButton: true,
          title: "Se necesita completar toda la documentación obligatoria",
        });
      }
    };

    const download = async () => {
      axios({ url: Row.value.appraise.file, method: "GET", responseType: "blob", onDownloadProgress: (progressEvent) => {
          var percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          // console.log(percentCompleted);
        },
      }).then((response) => {
        const file_name = Row.value.appraise.file.split("storage/")[1];
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", file_name);
        document.body.appendChild(link);
        link.click();
      });
    };

    const can = (permissions) => {
      // return true;
      const Permissions = store.getters.getPermissions;

      if (_.size(Permissions)) {
        if (Array.isArray(permissions)) {
          let flag = false;

          _.map(permissions, (permission) => {
            if (Permissions.indexOf(permission) !== -1) {
              flag = true;
            }
          });

          return flag;
        } else {
          return Permissions.indexOf(permissions) !== -1;
        }
      }

      return false;
    };

    onBeforeMount(async () => {
      let userRolObj: any = localStorage.getItem("userRole");
      userRole.value = JSON.parse(userRolObj);
      // console.log(userRole.value);

      id.value = route.params.id;
      drop_down_status.value = false;
      edit_final_offer.value = false;

      // if(userRole.value.name != undefined && userRole.value.name == "Valuador"){

      // } else if(userRole.value.name != undefined && userRole.value.name == "Coordinador de agencia") {

      // }
      
      
      hideModal(modalStatusRef.value);
      await getUser();
      await getData();
      await getStatus();
      await getAgencies();
      await getTipoRol();
    });

    onMounted(async () => {
      
      // console.log("ROL", userRole.value.tipo.name);
    });

    return {
      route,
      userRole,

      id,
      Row,
      list_agencies,
      drop_down_status,
      edit_final_offer,
      edit_initial_offer,
      type_offer,
      status_ref,

      getUser,
      getData,
      getAgencies,
      setAgencyData,
      getStatus,
      addOffer,
      editOffer,
      cancelOffer,
      formatPrice,
      changeCheckedDocument,
      changeDropDownStatus,
      saveStatus,
      changeStatus,
      modalStatusRef,
      download,
      tipoCita,
      can,
      store,
      loading,
      user,
      getTipoRol,
      tipoRol
    };
  },
};
