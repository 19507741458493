import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "dataTables_wrapper dt-bootstrap4 no-footer" }
const _hoisted_2 = { class: "table-responsive" }
const _hoisted_3 = {
  role: "row",
  class: "text-start text-gray-400 fw-bold fs-5 text-capitalize gs-0"
}
const _hoisted_4 = { class: "fs-base fw-normal text-white" }
const _hoisted_5 = { class: "odd" }
const _hoisted_6 = {
  colspan: "{{ tableHeader.length -1 }}",
  class: "dataTables_empty"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("table", {
        id: "kt_customers_table",
        role: "grid",
        class: _normalizeClass([[_ctx.loading && 'overlay overlay-block'], "table align-middle border border-bottom-0 border-1 border-gray-600 fs-6 gy-5 dataTable no-footer"])
      }, [
        _createElementVNode("thead", null, [
          _createElementVNode("tr", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tableHeader, (row, i) => {
              return (_openBlock(), _createElementBlock("th", {
                key: i,
                tabindex: "0",
                rowspan: "1",
                colspan: "1",
                style: {"cursor":"pointer","background-color":"#191f26","color":"white","padding-left":"10px"},
                class: _normalizeClass([ row.name && 'min-w-125px', row.sortable !== false && 'sorting', _ctx.tableHeader.length -1 === i && 'text-start', _ctx.currentSort === `${row.sortingField ? row.sortingField : row.key}desc` && 'sorting_desc', _ctx.currentSort === `${row.sortingField ? row.sortingField : row.key}asc` && 'sorting_asc', ])
              }, _toDisplayString(row.name), 3))
            }), 128))
          ])
        ]),
        _createElementVNode("tbody", _hoisted_4, [
          (_ctx.getItems.length)
            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.getItems, (item, i) => {
                return (_openBlock(), _createElementBlock("tr", {
                  key: i,
                  class: "odd",
                  style: {"border-bottom":"1px solid #393840"}
                }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tableHeader, (cell, i) => {
                    return (_openBlock(), _createElementBlock("td", {
                      key: i,
                      class: _normalizeClass({ 'text-start': _ctx.tableHeader.length -1 === i }),
                      style: {"background-color":"#111518","padding-left":"10px"}
                    }, [
                      _renderSlot(_ctx.$slots, `cell-${cell.key}`, { row: item }, () => [
                        _createTextVNode(_toDisplayString(item[cell.key]), 1)
                      ])
                    ], 2))
                  }), 128))
                ]))
              }), 128))
            : _createCommentVNode("", true),
          _createElementVNode("template", null, [
            _createElementVNode("tr", _hoisted_5, [
              _createElementVNode("td", _hoisted_6, _toDisplayString(_ctx.emptyTableText), 1)
            ])
          ])
        ])
      ], 2)
    ])
  ]))
}